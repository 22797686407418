.common_block_extension {
  padding: 1.5rem;
  &__underscored {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--primary-gray-color);
      bottom: 0;
      left: 0;
    }
  }
}