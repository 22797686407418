.detailed-project-analytics-shorts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.detailed-project-analytics-short {
  background-color: var(--primary-gray-color);
  padding: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.25rem;
  &__text {
    font-size: 1.125rem;
    line-height: 120%;
  }
  &__subtext {
    font-weight: 400;
    color: var(--secondary-dark-color);
    font-size: calc(0.75rem + 1px);
    line-height: 140%;
  }
}