@use "src/assets/scss/abstracts/variables" as v;

.sell_confirmation {
  &__heading {
    font-size: 2rem;
    margin-bottom: 0.75rem;
    text-align: center;
  }
  &__commission {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &__commission_amount {
    line-height: 120%;
    letter-spacing: v.$default-letter-spacing;
    font-size: 1.5rem;
    &, & * {
      color: var(--primary-blue-color) !important;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__link {
    text-decoration: underline;
  }
}