@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

$grid-columns: 9.5rem 7rem 7rem 4rem 9rem 7.5rem 7.5rem;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "id target paid rate term schedule next";
  grid-column-gap: 1.5rem;
}