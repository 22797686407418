@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

.funding-request-identification {
  padding-block: 4.5rem;
  &__heading {
    margin-bottom: 0.75rem;
  }
  &__form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.75rem;
    &--three {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  &--full {
    grid-column: 1 / -1;
  }
  &__subheader {
    font-size: 1.125rem;
    line-height: 1.25rem;
    grid-column: 1 / -1;
    font-family: "TT Firs Neue", sans-serif;
  }
  &__rating {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  &__element {
    cursor: pointer;
    padding: 0.75rem;
    border-radius: 0.625rem;
    background-color: var(--primary-gray-color);
    color: var(--secondary-dark-color);
    @include m.centerWithFlex;
    transition: v.$default-transition;
    &.active {
      background-color: var(--primary-blue-color);
      color: var(--white-color);
    }
  }
  &__link {
    text-decoration: underline;
  }
  &__source, &__approve {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}