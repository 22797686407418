.my_investment_link {
  padding-block: 0.75rem;
  padding-inline: 2.5rem;
  & > * {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.25rem;
    color: var(--black-color);
  }
}