@use "src/assets/scss/abstracts/mixins" as m;

.detailed-project {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 18.75rem;
    grid-template-rows: 1fr;
    grid-column-gap: 2.5rem;
  }
  &__main {
    max-width: 100%;
    overflow: hidden;
  }
  &__back {
    margin-bottom: 1rem;
  }
  &__investment_id {
    margin-bottom: 0.5rem;
  }
  &__heading {
    margin-bottom: 1.5rem;
  }
  &-section {
    overflow-y: scroll;
    scrollbar-width: none;
    max-height: calc(100% - 1.5rem);
  }
  &__placeholder {
    position: relative;
    @include m.centerWithFlex;
  }
  &__loader {
    @include m.box(100%);
    position: relative;
  }
}