.funding-request-sign {
  padding-block: 4.5rem;
  &__heading {
    font-size: 2rem;
    line-height: 2.375rem;
    margin-bottom: 1rem;
  }
  &__statuses {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}