@use "../../../../assets/scss/abstracts/mixins" as m;

.funding-request-upload {
  padding-top: 4.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &__heading {
    font-size: 2rem;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__requirement {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    & > img {
      margin-top: 0.25rem;
      @include m.box(1rem);
    }
  }
}