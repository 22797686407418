@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/functions" as f;
@use "src/components/pages/market/primary-market/grid" as gr;

$grid-areas: name, sum, rate, term, type, collected, actions;

.primary_market_offer {
  @include gr.gridValues;
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
}