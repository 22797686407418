@use "../grid" as gr;

$grid-areas: id, target, paid, rate, date, schedule, next;

.my_active_project {
  & > a {
    @include gr.gridValues;
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
  &__paid, &__term {
    flex-direction: column;
    align-items: flex-start !important; //TODO: FIX IMPORTANT
    gap: 0.25rem;
  }
}