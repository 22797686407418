@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.option-box {
 &__content {
   position: absolute;
   width: 100%;
   top: 115%;
   left: 0;
   overflow: hidden;
   max-height: 0;
   z-index: 5;
   background-color: var(--primary-gray-color);
   border-radius: 0.625rem;
   transition: v.$default-transition;
   box-shadow: v.$default-box-shadow-higher;
   &.active {
     overflow: scroll;
     scrollbar-width: none;
     max-height: 14rem;
   }
 }
  &__item {
    cursor: pointer;
    @include m.centerWithFlex;
    padding-block: 0.375rem;
    &.active {
      color: var(--primary-blue-color);
    }
  }
}