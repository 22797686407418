@use "src/assets/scss/abstracts/mixins" as m;

.my_project {
  cursor: pointer;
  min-height: 4rem;
  height: 1px;
  padding: 1rem 2.5rem;
  & > a {
    @include m.box(100%);
    &, &:visited, &:active {
      color: var(--primary-dark-color);
    }
  }
  &___small {
    padding-block: 0.7rem;
  }
}