@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

.container {
  height: 100%;
  width: calc(min(100%, 90rem));
  margin-inline: auto;
  @include m.media("desktop") {
    width: calc(min(100%, 52.125rem));
  }
  @include m.media("tablet") {
    min-width: 20rem;
    width: calc(min(100%, 23.4375rem));
  }
}