@use "src/assets/scss/abstracts/variables" as v;

.secondary-navigation-link {
  height: 100%;
  & > * {
    display: flex;
    align-items: center;
    height: 100%;
    color: var(--secondary-dark-color);
    cursor: pointer;
    position: relative;
    letter-spacing: v.$default-letter-spacing-higher;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1px;
      left: 0;
      background-color: var(--stroke-gray-color);
    }
    &.active {
      &::after {
        background-color: var(--primary-dark-color);
      }
    }
    &:hover, &.active {
      color: var(--primary-dark-color);
    }
  }
}