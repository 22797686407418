@use "../../../assets/scss/abstracts/mixins" as m;
@use "../../../assets/scss/abstracts/variables" as v;

.my-modal {
  position: fixed;
  @include m.centerWithPositioning;
  background-color: var(--white-color);
  padding: 0.5rem;
  border-radius: 1rem;
  width: calc(min(27.5rem, calc(100% - 1rem)));
  min-height: 30rem;
  @include m.centerWithFlex;
  padding-inline: 2.5rem;
  flex-direction: column;
  z-index: 1055;
  &__content {
    position: relative;
  }
  &__close {
    position: absolute;
    top: 1.125rem;
    right: 1.125rem;
    @include m.box(2.5rem);
    border-radius: 50%;
    background-color: var(--primary-green-color-lighter);
    cursor: pointer;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.5rem;
      height: 2px;
      background-color: var(--black-color);
      transition: all 0.3s;
    }
    &::before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
    &::after {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
    &:hover {
      &::before {
        transform: translateX(-50%) translateY(-50%) rotate(135deg);
      }
      &::after {
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
      }
    }
  }
  &--enter {
    opacity: 0;
    transform: translateX(-50%) translateY(-10rem);
  }
  &--enter-active {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
    transition: all 0.5s;
  }
  &--exit {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
  &--exit-active {
    opacity: 0;
    transform: translateX(-50%) translateY(-10rem);
    transition: all 0.5s;
  }
}