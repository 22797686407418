@mixin analyticsRow() {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    background-color: var(--primary-gray-color);
  }
  padding-block: 1rem;
  &:last-child {
    padding-bottom: 0;
  }
}