@use "../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../assets/scss/abstracts/variables" as v;

.lender-navigation {
  &-block {
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    background-color: var(--white-color);
    max-height: calc(100vh - 5.5rem);
  }
}