.identification-phone {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  &__submit {
    cursor: pointer;
    color: var(--primary-blue-color);
  }
  &__verified {
    color: var(--primary-green-color);
  }
}