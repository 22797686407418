.contact_info_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__link {
    color: var(--primary-blue-color);
    text-decoration: underline;
    font-size: 0.75rem;
  }
  &__loading {
    text-align: center;
    padding-block: 0.375rem;
  }
}