@use "../../../../../assets/scss/abstracts/mixins" as m;

.detailed-project-analytics-list {
  @include m.mountAnimation;
  &__table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem;
  }
  &__columns {
    display: flex;
    flex-direction: column;
  }
  &__column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
      padding-bottom: 1rem;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background-color: var(--primary-gray-color);
      }
    }
    &:not(:first-child) {
      padding-top: 1rem;
    }
    &-header {
      font-weight: 400;
      color: var(--secondary-dark-color);
    }
  }
}