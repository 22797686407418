@use "../grid" as gr;

.default_investments {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &__header {
    @include gr.gridValues;
  }
}