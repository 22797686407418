.enter_company {
  display: flex;
  flex-direction: column;
  &__company {
    margin-bottom: 0.75rem;
  }
  &__input {
    position: relative;
    margin-bottom: 1rem;
  }
}