@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.toggle {
  width: 2.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  transition: v.$default-transition;
  background-color: var(--stroke-gray-color);
  position: relative;
  cursor: pointer;
  &__button {
    @include m.box(1.25rem);
    background-color: var(--white-color);
    border-radius: 1.25rem;
    position: absolute;
    transition: v.$default-transition;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
  }
  &:not(.active):hover {
    & > .toggle__button {
      background-color: var(--secondary-dark-color);
    }
  }
  &.active {
    background-color: var(--primary-blue-color);
    & > .toggle__button {
      left: calc(50% - 2px);
    }
  }
}