.status-block {
  border-radius: 0.75rem;
  padding: 0.3rem 0.35rem 0.3rem 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  width: fit-content;
  &--success {
    color: var(--primary-green-color);
    background-color: var(--secondary-green-color);
  }
}