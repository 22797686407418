@use "src/assets/scss/abstracts/mixins" as m;

.my_investments {
  height: 100%;
  &__section {
    padding-top: 2.5rem;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  &__stats, &__heading, &__navigation {
    padding-inline: 2.5rem;
  }
  &__heading {
    margin-bottom: 1.5rem;
  }
  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  &__header {
    padding-top: 1rem;
    padding-inline: 2.5rem;
  }
  &__content {
    max-height: 100%;
    overflow-y: scroll;
    @include m.hideScrollbar;
  }
  &__list {

  }
}