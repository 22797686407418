@use "../../../../../assets/scss/abstracts/mixins" as m;

.detailed-project-summary {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  &__block {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__datatable {
    & > .detailed-project-datatable__row {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  &__founders {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}