@use "../../../../../assets/scss/abstracts/mixins" as m;

.detailed-project-founder {
  padding: 1.5rem;
  background-color: var(--primary-gray-color);
  border-radius: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__data {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-gap: 1rem 0;
  }
  &__column {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    & > * {
      line-height: 140%;
    }
  }
}