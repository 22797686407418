@use "../../../../../assets/scss/abstracts/mixins" as m;

.detailed-project-analytics {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__options {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
  &__option {
    @include m.centerWithFlex;
  }
  &__receipts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
  }
  &__receipt {
    background-color: var(--primary-gray-color);
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 1.5rem;
    border-radius: 0.75rem;
    & > * {
      line-height: 1.25rem;
      &:first-child {
        font-size: 1.125rem;
      }
    }
  }
}

.year-select {
  &__header {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    min-width: 3.375rem;
  }
  &__heading {
    letter-spacing: -0.04em;
    font-size: 0.875rem;
    line-height: 100%;
  }
}

.detailed-project-accounting__datatable {
  & > .detailed-project-datatable__row {
    display: grid;
    grid-template-columns: 17.5rem 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    & > .detailed-project-datatable__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}