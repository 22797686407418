.content-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &--small {
    padding: 1.5rem;
  }
  &--normal {
    padding: 2.5rem;
  }
}