@use "../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../assets/scss/abstracts/variables" as v;

.header {
  background-color: var(--white-color);
  padding-inline: 1.5rem;
  min-height: 4rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__main {
    display: flex;
    gap: 3rem;
  }
  &__logo {
    position: relative;
    & img {
      aspect-ratio: 5 / 2;
      max-width: 4rem;
    }
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 1.5rem;
      @include m.centerColumnWithPositioning;
      right: -1.5rem;
      background-color: var(--stroke-gray-color);
    }
  }
  &__money {
    display: flex;
    align-items: center;
    gap: 1rem;
    &-links {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    &-link {
      border-radius: 0.75rem;
      & span {
        letter-spacing: v.$default-letter-spacing-higher;
      }
    }
  }
  &__balance {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
  }
}