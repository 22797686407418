.identification-status {
  padding-block: 4.5rem;
  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__loading {
    text-align: center;
  }
  &__heading {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }
  &__text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}