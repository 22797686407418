@use "src/assets/scss/abstracts/mixins" as m;

$grid-columns: minmax(12rem, 1fr) 4rem 7.5rem 7rem 6rem 6rem 8rem;
$grid-areas: "name rate date sum";

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: $grid-areas;
  grid-gap: 1.5rem;
}