@use "src/assets/scss/abstracts/variables" as v;

.paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  &__container {
    padding-block: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }
  &__pages {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &__input {
    display: block;
    padding-block: 0.5rem;
    letter-spacing: v.$default-letter-spacing-higher;
    text-align: center;
    line-height: 140%;
    font-size: 0.875rem;
    border-radius: 0.75rem;
    border: 1px solid var(--primary-gray-color);
    &:active, &:focus {
      outline: none;
    }
  }
}

.my-page-link {
  padding: 0.375rem 0.75rem;
  border: none;
  background: none;
  color: var(--black-color);
  &:hover {
    color: inherit;
  }
  &--active {
    background-color: var(--primary-gray-color);
    border-radius: 0.5rem;
    cursor: initial;
  }
}

.previous-page-link, .next-page-link {
  cursor: pointer;
  color: var(--black-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    color: inherit;
  }
  &--disabled {
    cursor: initial;
    pointer-events: none;
    color: var(--gray-dark-color);
  }
}