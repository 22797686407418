@use "src/assets/scss/abstracts/mixins" as m;

.my-loans {
  height: 100%;
  position: relative;
  &__new {
    position: absolute;
    @include m.centerLineWithPositioning;
    bottom: 1rem;
  }
  &__section {
    padding-top: 2.5rem;
  }
  &__navigation {
    margin-bottom: 1.5rem;
  }
  &__header, &__navigation {
    padding-inline: 2.5rem;
  }
}