@use "../grid" as gr;

$grid-areas: id, target, rate, date, schedule;

.my_refunded_project {
  & > a {
    @include gr.gridValues;
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
}