@use "src/assets/scss/abstracts/mixins" as m;

.input-password {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  &__input {
    border: none;
    font-size: 0.875rem;
    color: var(--primary-dark-color);
    line-height: 140%;
    height: 100%;
    width: 100%;
    &:active, &:focus {
      outline: none;
    }
  }
  &__inner {
    display: flex;
    align-items: stretch;
    padding: calc(0.5rem - 1px) 0.75rem;
    border: 1px solid var(--primary-gray-color);
    min-height: 2.5rem;
    width: 100%;
    border-radius: 0.75rem;
    &--error {
      border-color: var(--error-color);
    }
  }
  &__left {
    flex: 1;
  }
  &__eye {
    @include m.centerWithFlex;
    cursor: pointer;
  }
}