@use "src/assets/scss/abstracts/mixins" as m;

.money_progress {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }
  &__right {
    text-align: right;
  }
  &__money {
    @include m.ttNeueFont;
    font-size: 1.125rem;
  }
  &__progress {

  }
}