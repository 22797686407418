@use "../../../../../assets/scss/abstracts/mixins" as m;

.detailed-project-invest {
  position: relative;
  &__container {
    position: fixed;
    width: 100%;
    z-index: 10;
    max-width: 18.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__right {
    text-align: right;
  }
  &__value {
    font-size: 0.875rem;
    color: var(--primary-dark-color);
    line-height: 140%;
  }
  &__information {
    display: flex;
    align-items: center;
    gap: 0.35rem;
  }
}