@use "src/assets/scss/abstracts/mixins" as m;

.market_count {
  position: relative;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--additional-dark-color);
  &::before {
    content: "";
    position: absolute;
    left: -0.75rem;
    @include m.centerColumnWithPositioning;
    height: 0.75rem;
    width: 1px;
    background-color: var(--additional-dark-color);
  }
}