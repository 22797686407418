@use "../grid" as gr;
@use "src/assets/scss/abstracts/functions" as f;

$grid-areas: name, rate, date, return, sum, paid, actions;

.loan_payment_investment {
  & > a {
    @include gr.gridValues;
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: $area;
    }
  }
  &__sell {
    z-index: 5;
  }
}