.funding-request-requisites {
  &__header {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
  }
  &__heading {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    margin-bottom: 0.5rem;
  }
  &__text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &__content {
    padding-block: 1.5rem;
    position: relative;
    border-block: 1px solid var(--primary-gray-color);
  }
  &__list {
    list-style-type: disc;
    padding-left: 1rem;
  }
  &__footer {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__qr {
    margin-bottom: 1.5rem;
  }
}