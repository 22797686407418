.analytics_platform_statistics {
  position: fixed;
  width: 100%;
  max-width: 18.75rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 0.75rem;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &:not(:last-child) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -1.5rem;
        width: calc(100% + 3rem);
        height: 2px;
        background-color: var(--primary-gray-color);
      }
    }
  }
  &__investors {
    & > div {
      word-break: break-word;
    }
  }
  &__text {
    white-space: nowrap;
  }
}