@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.language {
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 12rem;
  box-shadow: v.$default-box-shadow;
  border: 1px solid var(--secondary-gray-color);
  cursor: pointer;
  transition: v.$default-transition;
  &__name, &__short {
    transition: v.$default-transition;
  }
  &__short {
    color: var(--secondary-gray-color);
    font-size: 0.75rem;
    line-height: 140%;
    letter-spacing: v.$default-letter-spacing;
  }
  &:hover {
    background-color: var(--primary-blue-color);
    scale: 1.1;
    & .language__name, & .language__short {
      color: var(--white-color);
    }
  }
  &__image {
    @include m.box(1.5rem);
    object-fit: contain;
    & > img {
      max-width: 100%;
    }
  }
  &__name {
    margin-bottom: 0.25rem;
  }
  &__selected {
    @include m.centerWithFlex;
    margin-left: auto;
  }
}