@use "../grid" as gr;

$grid-areas: id, target, collected, rate, date, schedule;

.my_fundraising_project {
  & > a {
    @include gr.gridValues;
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
  &__collected {
    align-items: flex-start;
    flex-direction: column;
    gap: 0.25rem;
  }
}