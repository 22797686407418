@use "src/assets/scss/abstracts/mixins" as m;

.login {
  @include m.box(100%);
  @include m.centerWithFlex;
  &__container {
    width: calc(min(22.5rem, 90%));
    display: flex;
    flex-direction: column;
  }
  &__heading {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}