@use "variables" as v;

@mixin centerWithFlex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin centerWithPositioning($additionalTransform: "") {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) #{$additionalTransform};
}

@mixin centerLineWithPositioning($additionalTransform: "") {
  left: 50%;
  transform: translateX(-50%) #{$additionalTransform};
}

@mixin centerColumnWithPositioning($additionalTransform: "") {
  top: 50%;
  transform: translateY(-50%) #{$additionalTransform};
}

@mixin box($size) {
  width: $size;
  height: $size;
}

@mixin ttNeueFont() {
  font-family: 'TT Firs Neue', sans-serif !important;
}

$widths: (min: min-width, max: max-width);

@mixin media($breakpoint, $width: "max") {
  @if (map-has-key($widths, $width) == false) {
    @error "Please, provide valid width parameter";
  }
  @if (map-has-key(v.$container-breakpoints, $breakpoint)) {
    $breakpoint: map-get(v.$container-breakpoints, $breakpoint);
    @if ($width == "max") {
      $breakpoint: calc($breakpoint - 1px);
    }
    @media screen and (#{$width + "-width"}: $breakpoint) {
      @content
    }
  } @else if (type-of($breakpoint) == "number") {
    @media screen and (#{$width + "-width"}: $breakpoint) {
      @content
    }
  } @else {
    @error "Please, provide valid breakpoint";
  }
}

@mixin hideScrollbar() {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin hideOnBreakpoint($breakpoint, $width: "max") {
  @include media($breakpoint, $width) {
    display: none !important;
  }
}

@mixin underscore($width: 100%, $top: 100%, $height: 2px) {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: #{$top};
    width: 0;
    @include centerLineWithPositioning;
    height: #{$height};
    background-color: var(--primary-green-color);
    transition: all 0.3s;
  }
  &.active, &:hover {
    &::after {
      width: #{$width};
    }
  }
}

@mixin lineClamp($number) {
  @if(type-of($number) != "number") {
    @error "Provide number of lines as number type";
  }
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
}

@mixin supportsSubgrid($value: true) {
  @if($value) {
    @supports (grid-template-columns: subgrid) {
      @content
    }
  } @else {
    @supports not (grid-template-columns: subgrid) {
      @content
    }
  }
}

@mixin mountAnimation() {
  transition: all 0.5s;
  opacity: 1;
  &:not(.mounted) {
    opacity: 0;
  }
}

@mixin css3-prefixing($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}

$weight-map: ("Bold": 700, "Light": 300, "Medium": 500, "Regular": 400);

@mixin font-face($name, $weight, $specificName: $name) {
  @if (type-of($weight) == list) {
    @each $element in $weight {
      @include innerFontFace($name, $element, $specificName);
    }
  } @else if (type-of($weight) == string) {
    @include innerFontFace($name, $weight, $specificName);
  }
}

@mixin innerFontFace($name, $weight, $specificName) {
  $fullName: #{$name}-#{$weight};
  @font-face {
    font-family: #{$specificName};
    src: local(#{$specificName}),
    url('../../../assets/fonts/#{$name}/#{$fullName}/#{$fullName}.ttf') format('truetype'),
    url('../../../assets/fonts/#{$name}/#{$fullName}/#{$fullName}.woff') format('woff'),
    url('../../../assets/fonts/#{$name}/#{$fullName}/#{$fullName}.woff2') format('woff2');
    font-weight: #{map-get($weight-map, $weight)};
    font-style: normal;
  }
}