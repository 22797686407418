@use "src/assets/scss/abstracts/mixins" as m;

$grid-columns: minmax(12rem, 1fr) 7rem 7rem 9rem 4rem 8rem 7rem;
$grid-areas: "name target collected sum interest date schedule";

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: $grid-areas;
  grid-gap: 1.5rem;
}