.identification-form {
  padding-block: 4.5rem;
  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__loading {
    position: relative;
  }
  &__heading {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }
  &__subheading {
    font-size: 1.125rem;
    grid-column: 1 / -1;
    margin-bottom: 0.75rem;
  }
  &__personal {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.75rem;
  }
  &__text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}