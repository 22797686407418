@use "../../../../../../assets/scss/abstracts/mixins" as m;

.common-aside-step {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &__number {
    @include m.centerWithFlex;
    border-radius: 50%;
    @include m.box(1.5rem);
    background-color: rgba(94, 100, 117, 0.12);
  }
}

.common-aside-element {
  &.finished .common-aside-step__number {
    color: var(--additional-green-color);
    background-color: rgba(22, 185, 87, 0.16);
  }
  &.active .common-aside-step__number {
    background-color: rgba(255, 255, 255, 0.16);
    color: var(--white-color);
  }
}