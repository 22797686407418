@use "../grid" as gr;

$grid-areas: id, name, term, rate, amount;

.archived_project {
  padding: 1.5rem;
  @include gr.gridValues;
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
  & > div {
    display: flex;
    align-items: center;
  }
}