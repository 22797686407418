@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.projects_filter_modal {
  padding: 2rem;
  width: calc(min(100%, 34rem));
  &__heading {
    margin-bottom: 1rem;
  }
  &__input_group {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
  }
  &__input {
    max-width: 100%;
    padding: calc(0.75rem - 1px) 0.75rem;
    background-color: var(--white-color);
    border: 1px solid var(--stroke-gray-color);
    border-radius: 0.75rem;
    &, &::placeholder {
      letter-spacing: v.$default-letter-spacing-higher;
      line-height: 140%;
      font-size: 0.875rem;
      font-weight: 400;
    }
    &::placeholder {
      color: var(--additional-dark-color);
    }
    &:focus {
      outline: none;
    }
  }
  &__label {
    grid-column: 1 / -1;
    margin-bottom: 0.25rem;
  }
  &__buttons {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__schedule {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__schedule_types {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  &__schedule_type {
    cursor: pointer;
    background-color: var(--primary-gray-color);
    border-radius: 0.625rem;
    padding: calc(0.5rem + 1px) 0.75rem;
    @include m.centerWithFlex;
    color: var(--secondary-dark-color);
    transition: v.$default-transition;
    &___active {
      background-color: var(--secondary-dark-color);
      color: var(--primary-gray-color);
    }
  }
  &__reset {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}