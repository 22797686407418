@use "../../../../assets/scss/abstracts/variables" as v;

#overlay {
  position: fixed;
  inset: 0;
  pointer-events: none;
  user-select: none;
  z-index: 50;
  opacity: 0;
  background-color: rgba(14, 17, 24, 0.72);;
  transition: opacity 0.25s ease-in;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}