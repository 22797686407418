@use "src/assets/scss/abstracts/mixins" as m;

.auth_header {
  padding-block: 1.375rem;
  &__link {
    cursor: pointer;
  }
  &__logo {
    max-width: 7rem;
  }
  &__container {
    @include m.centerWithFlex;
    position: relative;
  }
}