.detailed-project-progress {
  background-color: var(--primary-gray-color);
  padding: 1.5rem 1.5rem 0;
  border-radius: 0.75rem;
  min-height: 12.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  &__text {
    line-height: 1.25rem;
    text-align: center;
  }
}