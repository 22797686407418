@use "src/assets/scss/abstracts/variables" as v;

.medium_text {
  font-weight: 400;
  line-height: 140%;
  letter-spacing: v.$default-letter-spacing-higher;
  color: var(--secondary-dark-color);
  &___accent {
    font-weight: 500;
    color: var(--primary-dark-color);
  }
}