@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

@use "src/components/pages/archive/grid" as gr;

.archive {
  &__section {
    padding-top: 1.5rem;
    height: 100%;
    overflow-y: scroll;
    @include m.hideScrollbar;
  }
  &__heading {
    padding-inline: 1.5rem;
  }
  &__list_heading {
    padding-inline: 1.5rem;
    @include gr.gridValues;
  }
}