@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.secondary-button {
  @include m.centerWithFlex;
  border-radius: 0.625rem;
  padding: 0.5rem 0.75rem;
  background-color: var(--gray-light-color);
  font-weight: 500;
  color: var(--black-color);
  cursor: pointer;
  border: none;
  letter-spacing: v.$default-letter-spacing-higher;

  transition: v.$default-transition;
  &--blue {
    background-color: var(--primary-blue-color);
    color: var(--white-color);
    &:hover {
      background-color: #164ed9;
    }
  }
  &--gray {
    background-color: var(--primary-gray-color);
    color: var(--black-color);
    &:hover {
      background-color: #e1e4eb;
    }
  }
  &--expanded {
    width: 100%;
  }
}