@use "../grid" as gr;
@use "src/assets/scss/abstracts/functions" as f;

$grid-areas: name, rate, date, sum;

.refunded_investment {
  & > a {
    @include gr.gridValues;
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: $area;
    }
  }
}