@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.analytics-option-box {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    line-height: 100%;
  }
  &__content {
    background-color: var(--white-color);
    box-shadow: v.$default-box-shadow-higher;
  }
  &__item {
    padding-left: 1.5rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      @include m.centerColumnWithPositioning;
      @include m.box(1rem);
      border: 1px solid var(--stroke-gray-color);
      border-radius: 50%;
    }
  }
  & .option-box__head {
    background-color: var(--primary-dark-color);
    color: var(--white-color);
  }
  & .option-box__list {
    border-radius: 0.625rem;
    border: 1px solid var(--stroke-gray-color);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  & .option-box__item.active {
    & > .analytics-option-box__item {
      color: var(--primary-dark-color);
      &::before {
        border: none;
        background-color: var(--primary-blue-color);
      }
      &::after {
        content: "";
        position: absolute;
        @include m.box(0.375rem);
        border-radius: 50%;
        background-color: var(--white-color);
        @include m.centerColumnWithPositioning;
        left: calc(0.25rem + 1px);
      }
    }
  }
}