@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

$grid-columns: 9.5rem 9.5rem 6rem 7.5rem 9.5rem minmax(9.5rem, 1fr);

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "id target rate date schedule status";
  grid-column-gap: 1.5rem;
}