.language_select_modal {
  &__heading {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}