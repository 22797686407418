@use "../../../../../assets/scss/abstracts/variables" as v;
@use "../../../../../assets/scss/abstracts/mixins" as m;

.identification-passport-files {
  background-color: var(--white-color);
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid var(--stroke-gray-color);
  box-shadow: v.$default-box-shadow;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  &__header {
    font-size: 1.125rem;
    line-height: 1.25rem;
    grid-column: 1 / -1;
    font-family: "TT Firs Neue", sans-serif;
  }
  &__text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &__uploads {
    margin-top: 0.75rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}