@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.auth_chat_button {
  @include m.box(2.5rem);
  @include m.centerWithFlex;
  border-radius: 50%;
  background-color: var(--primary-gray-color);
  cursor: pointer;
  transition: v.$default-transition;
  &:hover {
    background-color: var(--secondary-dark-color);
  }
}