.progress-bar {
  width: 100%;
  min-height: 0.5rem;
  background-color: var(--primary-gray-color);
  overflow: hidden;
  border-radius: 6.25rem;
  position: relative;
  &__filled {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 6.25rem;
    background-color: var(--primary-blue-color);
  }
}