@use "src/assets/scss/abstracts/mixins" as m;

.layout-lender-identification {
  min-height: 100vh;
  height: 1px;
  padding: 0.5rem;
  &__loader {
    @include m.centerWithFlex;
    flex-direction: column;
    gap: 2rem;
    position: relative
  }
}