@use "src/assets/scss/abstracts/mixins" as m;

$grid-columns: minmax(18.25rem, 1fr) minmax(10rem, 1fr) 4.5rem 4.5rem 7.5rem 4.5rem 1fr;

$grid-areas: "name sum rate term type collected actions";

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: $grid-areas;
  grid-gap: 1rem;
}