@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.confirmation-block {
  @include m.centerWithFlex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  &__heading {
    font-size: 2rem !important;
    letter-spacing: v.$default-letter-spacing !important;
    margin-bottom: 0.75rem !important;
  }
  &__text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: v.$default-letter-spacing-higher;
  }
}