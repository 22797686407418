@use "src/assets/scss/abstracts/variables" as v;

.company_autocomplete {
  position: absolute;
  top: calc(100% + 1rem);
  max-height: 15rem;
  overflow-y: scroll;
  width: 100%;
  z-index: 2;
  box-shadow: v.$default-box-shadow-higher;
  background-color: var(--white-color);
  border-radius: 0.75rem;
  border: 1px solid var(--stroke-gray-color);
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__element {
    cursor: pointer;
    padding: 1rem;
    transition: all 0.3s;
    background-color: var(--white-color);
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    &:not(:last-child) {
      border-bottom: 1px solid var(--stroke-gray-color);
    }
    &:hover {
      background-color: var(--primary-gray-color);
    }
  }
  &__info {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  &__name, &__address {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__id {
    color: var(--primary-blue-color);
  }
}