@use "../../../../assets/scss/abstracts/mixins" as m;

.funding-request {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  &__inner {
    max-height: 100%;
    overflow-y: scroll;
    @include m.hideScrollbar;
  }
  &__container {
    margin-inline: auto;
    width: calc(min(100%, 37.5rem));
  }
  &__heading {
    font-size: 2rem;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__element {
    &:not(:last-child) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1rem;
        left: 0;
        background-color: var(--stroke-gray-color);
      }
    }
  }
  &__subheader {
    margin-bottom: 0.75rem;
  }
}