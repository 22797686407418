@use "src/assets/scss/abstracts/variables" as v;

.detailed_project_document {
  background-color: var(--primary-gray-color);
  border-radius: 0.75rem;
  cursor: pointer;
  &__link {
    display: block;
    padding: 0.75rem 0.875rem;
    color: var(--primary-dark-color);
  }
  &__content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &__name {
    font-size: 0.875rem;
    line-height: 100%;
    font-weight: 550;
    letter-spacing: v.$default-letter-spacing;
  }
}