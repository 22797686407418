@use "src/assets/scss/abstracts/mixins" as m;

.my_detailed_project {
  display: grid;
  grid-template-columns: 1fr 18.75rem;
  grid-gap: 2.5rem;
  padding: 2.5rem 2.5rem 0;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  &__main {
    max-height: 100%;
    overflow-y: scroll;
    @include m.hideScrollbar;
  }
  &__container {
    padding-bottom: 2.5rem;
  }
  &__back {
    margin-bottom: 1rem;
  }
  &__heading {
    margin-bottom: 2.5rem;
  }
  &__aside {
    position: relative;
    &_container {
      position: fixed;
      width: 100%;
      z-index: 10;
      max-width: 18.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}