.sms-code-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    font-size: 2rem;
    line-height: 2.375rem;
    font-family: "TT Firs Neue", sans-serif;
    margin-bottom: 0.75rem;
    max-width: 16rem;
    text-align: center;
  }
  &__text {
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  &__input {
    position: relative;
    margin-bottom: 0.75rem;
  }
  &__timer {
    font-weight: 400;
    font-size: 0.75rem;
  }
}