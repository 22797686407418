.identification-passport {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.75rem;
  &--full {
    grid-column: 1 / -1;
  }
  &__header {
    font-size: 1.125rem;
    line-height: 1.25rem;
    grid-column: 1 / -1;
    font-family: "TT Firs Neue", sans-serif;
  }
}