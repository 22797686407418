@use "src/assets/scss/abstracts/mixins" as m;

.user-analytics {
  display: grid;
  grid-template-columns: 1fr 18.75rem;
  grid-column-gap: 2.5rem;
  height: 100%;
  overflow: hidden;
  &__main {
    max-width: 39rem;
    height: 100%;
    @include m.hideScrollbar;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
  &__stats {
    position: relative;
  }
  &__selects {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2rem;
  }
  &__chart {
    max-width: 39rem;
    margin-bottom: 2.5rem;
    & > * {
      max-width: 100%;
    }
  }
}