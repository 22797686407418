@use "../../../assets/scss/abstracts/mixins" as m;

@keyframes loader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  position: absolute;
  @include m.centerWithPositioning;
  @include m.box(2rem);
  inset: 0;
  margin: auto;
  animation: loader 2s linear infinite;
  clip-path: inset(0 0.5rem 0 0);
  border: 3px solid var(--primary-blue-color);
  border-radius: 50%;
  &--small {
    @include m.box(1rem);
    clip-path: inset(0 0.25rem 0 0);
    border: 2px solid var(--primary-blue-color);
  }
  &--white {
    border-color: var(--white-color);
  }
  &--dark {
    border-color: var(--primary-dark-color);
  }
}