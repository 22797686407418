@use "src/assets/scss/abstracts/mixins" as m;

@include m.font-face("Inter", ("Medium", "Regular", "Light", "Bold"));
@include m.font-face("TTFirsNeue", ("Medium", "Regular", "Light", "Bold"), "TT Firs Neue");

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  line-height: 1;
  background-color: var(--primary-gray-color);
  font-weight: 500;
  color: var(--primary-dark-color);
  font-size: 0.875rem;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}