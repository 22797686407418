@use "../../../../assets/scss/abstracts/mixins" as m;

.main-grid {
  display: grid;
  grid-template-columns: 12.5rem 1fr;
  grid-gap: 0.5rem;
  background-color: var(--primary-gray-color);
  &--subpage {
    @include m.box(100%);
    grid-template-columns: 18rem 1fr;
  }
}