@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.date-select {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  &__input {
    padding: 0.625rem 0.75rem;
    border: 1px solid var(--primary-gray-color);
    min-height: 2.5rem;
    width: 100%;
    font-size: 0.875rem;
    border-radius: 0.75rem;
    cursor: pointer;
    line-height: 140%;
    color: var(--primary-dark-color);
    font-weight: 400;
  }
  & .react-date-picker {
    &__wrapper {
      border: none;
      cursor: pointer;
    }
    &__calendar {
      inset: 175% auto auto 0 !important;
      width: 250px;
    }
  }
}