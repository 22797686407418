.page-loader {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  &__inner {
    height: 3px;
    width: 100%;
    background-color: var(--error-color);
  }
}