.detailed-project-datatable__row {
  padding-block: 1rem;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  &:not(:last-child) {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -0;
      height: 1px;
      width: 100%;
      background-color: var(--primary-gray-color);
    }
  }
}