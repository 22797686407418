.round-progress-bar {
  &__background, &__progress {
    fill: none;
  }
  &__background {
    stroke: var(--white-color);
  }
  &__progress {
    stroke: var(--primary-blue-color);
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  &__text {
    font-family: "TT Firs Neue", sans-serif;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: var(--primary-blue-color);
  }
}