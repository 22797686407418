@use "src/assets/scss/abstracts/mixins" as m;

.svg-container {
  @include m.centerWithFlex;
  &--small {
    @include m.box(1rem);
  }
  &--medium {
    @include m.box(1.5rem);
  }
  &--large {
    @include m.box(2.5rem);
  }
}