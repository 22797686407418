@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.analytics_multi_select {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }
  &__option {
    padding-left: 1.5rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      @include m.centerColumnWithPositioning;
      @include m.box(1rem);
      border: 1px solid var(--stroke-gray-color);
      border-radius: 0.25rem;
    }
  }
  &__content {
    background-color: var(--white-color);
    box-shadow: v.$default-box-shadow-higher;
    min-width: 10rem;
  }
  & .multi-select__head {
    background-color: var(--primary-dark-color);
    color: var(--white-color);
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    border-radius: 0.75rem;
  }
  & .multi-select__list {
    border-radius: 0.625rem;
    border: 1px solid var(--stroke-gray-color);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  & .multi-select__element.selected {
    & > .analytics_multi_select__option {
      color: var(--primary-dark-color);
      &::before {
        border: none;
        background-color: var(--primary-blue-color);
      }
      &::after {
        content: "";
        position: absolute;
        @include m.box(1rem);
        background-repeat: no-repeat;
        background-position: center;
        top: 0.125rem;
        left: 0;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E")
      }
    }
  }
}