.funding-request-urls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__inputs, &__list {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  &__bottom {
    display: flex;
    justify-content: center;
  }
  &__add {
    cursor: pointer;
    color: var(--black-color);
    font-weight: 550;
    line-height: 0.875rem;
    letter-spacing: -0.04em;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.funding-request-url {
  position: relative;
  &__input {
    padding: 0.625rem 0.75rem;
    border: 1px solid var(--primary-gray-color);
    min-height: 2.5rem;
    width: 100%;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    color: var(--primary-dark-color);
    line-height: 140%;
  }
  &__remove {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}