@use "../grid" as gr;
@use "src/assets/scss/abstracts/mixins" as m;

$grid-areas: id, target, rate, date, schedule, status;

.my_moderated_project {
  & > a {
    @include gr.gridValues;
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
}