.active_payment_schedule {
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__element {
    padding-block: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr) 1rem;
    grid-gap: 1.5rem;
    &:first-child {
      padding-top: 0;
    }
    &:not(:last-child) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: var(--stroke-gray-color);
      }
    }
  }
}