@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.information {
  @include m.box(0.875rem);
  border-radius: 50%;
  background-color: var(--primary-gray-color);
  @include m.centerWithFlex;
  position: relative;
  cursor: pointer;
  &__answer {
    cursor: initial;
    position: absolute;
    z-index: 1;
    background-color: var(--primary-dark-color);
    padding: 0.35rem 0.5rem;
    border-radius: 0.5rem;
    min-width: 15rem;
    left: 5rem;
    transform: translateX(-50%);
    bottom: 1.875rem;
    visibility: hidden;
    opacity: 0;
    &::after {
      content: "";
      position: absolute;
      left: 2.5rem;
      bottom: -0.375rem;
      background-image: url("../../../../assets/images/blact-rectangle.svg");
      height: 0.375rem;
      width: 0.75rem;
    }
    &--enter {
      visibility: hidden;
      opacity: 0;
      &-active {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s;
      }
      &-done {
        opacity: 1;
        visibility: visible;
      }
    }
    &--exit {
      opacity: 1;
      visibility: visible;
      &-active {
        opacity: 0;
        visibility: visible;
        transition: all 0.3s;
      }
      &-done {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__text {
    color: var(--white-color);
    font-size: 0.75rem;
    line-height: 140%;
  }
}