@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.input-phone {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 100%;
  &--error {
    border-color: var(--error-color);
  }
  &__container {
    flex: 1;
  }
  &__code {
    color: var(--black-color);
    @include m.centerWithFlex;
  }
  &__inner {
    padding: calc(0.5rem - 1px) 0.75rem;
    border: 1px solid var(--primary-gray-color);
    min-height: 2.5rem;
    width: 100%;
    max-width: 100%;
    border-radius: 0.75rem;
    display: flex;
    align-items: stretch;
    gap: 0.25rem;
    line-height: 140%;
    &--error {
      border-color: var(--error-color);
    }
  }
  &__input {
    border: none;
    color: var(--primary-dark-color);
    font-size: 0.875rem;
    height: 100%;
    width: 100%;
    &:active, &:focus {
      outline: none;
    }
  }
}