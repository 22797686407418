@use "../AnalyticsLoan.module" as alm;

.analytics_loan_row_header {
  width: max-content;
  @include alm.analyticsLoan;
  padding-bottom: 1rem;
  & > *:nth-child(-n + 2) {
    padding-top: 1rem;
  }
  & > * {
    display: flex;
    align-items: center;
  }
}