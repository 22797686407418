@use "src/assets/scss/abstracts/variables" as v;

.common_sub_text {
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--secondary-dark-color);
  //Text Small
  &___small {
    font-size: 0.75rem;
    line-height: 140%;
    letter-spacing: v.$default-letter-spacing;
  }
}