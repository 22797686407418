.sub-page-main {
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  max-height: calc(100vh - 1rem);
  overflow-y: scroll;
  position: relative;
  &__container {
    width: calc(min(100%, 37.5rem));
  }
  &__link {
    position: absolute;
    inset: 1rem auto auto 1rem;
  }
}