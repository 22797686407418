@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.investment-confirmation {
  &__heading {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > span {
      font-family: inherit;
    }
  }
  &__placeholder {
    text-align: center;
    padding-block: 0.25rem;
  }
  &-main {
    background-color: var(--white-color);
    border: 1px solid var(--stroke-gray-color);
    border-radius: 1rem;
    box-shadow: v.$default-box-shadow;
    padding: 1.5rem;
    &__container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    &__result {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__result_amount {
      color: var(--primary-blue-color) !important;
    }
    &__submit {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    &__disclaimer {
      font-weight: 400;
      font-size: 0.75rem;
      text-align: center;
      color: var(--additional-dark-color);
    }
  }
  &__schedule {
    margin-block: 2rem;
    &-heading {
      margin-bottom: 1rem;
    }
  }
}