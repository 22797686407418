.funding-request-review {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &__header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  &__subheader {
    margin-bottom: 0;
  }
}