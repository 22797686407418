.register_lender {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__types {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  &__type {
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    background-color: var(--primary-gray-color);
    color: var(--secondary-dark-color);
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    transition: all 0.3s;
    &___active {
      background-color: var(--primary-blue-color);
      color: var(--white-color);
    }
  }
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__link {
    color: var(--primary-blue-color);
    text-decoration: underline;
    font-size: 0.75rem;
  }
  &__loading {
    text-align: center;
  }
}