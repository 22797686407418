@use "../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../assets/scss/abstracts/variables" as v;

.header-navigation {
  display: flex;
  align-items: center;
  &__list {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  &__element {

  }
  &__link {
    color: var(--primary-dark-color);
    font-size: 0.875rem;
    font-weight: 500;
    transition: v.$default-transition;
    &:hover, &.active {
      color: var(--secondary-dark-color);
    }
  }
}