@use "src/assets/scss/abstracts/mixins" as m;

.input-sms-code {
  &__container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }
  &__cell {
    width: 3rem;
    height: 3.5rem;
    @include m.centerWithFlex;
  }
  &__input {
    @include m.box(100%);
    outline: none;
    border: 1px solid var(--stroke-gray-color);
    border-radius: 0.75rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    @include m.centerWithFlex;
    text-align: center;
    &:focus {
      border-color: var(--primary-dark-color);
    }
  }
}