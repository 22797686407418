.deposit {
  font-weight: 400;
  &__container {
    margin-inline: auto;
    width: calc(min(100%, 37.5rem));
  }
  &__status {
    margin-bottom: 1rem;
  }
  &__text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    line-height: 135%;
  }
  &__list {
    padding-left: 0.75rem;
    list-style-type: disc;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    line-height: 135%;
  }
  &__qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
}