@use "../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../assets/scss/abstracts/variables" as v;

.funding-request-files {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  &__loader {
    position: absolute;
    inset: 0;
    background-color: rgba(v.$white-color, 0.75);
    z-index: 5;
    border-radius: 1rem;
  }
  &__inner {
    min-height: 5rem;
    border: 1px dashed var(--stroke-gray-color);
    @include m.centerWithFlex;
    flex-direction: column;
    padding: 1rem;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  &__upload {
    display: flex;
    align-items: center;
    & span {
      text-decoration: underline;
      color: var(--primary-blue-color);
    }
  }
  &__submit {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}