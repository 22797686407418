@use "../../../../assets/scss/abstracts/mixins" as m;

.auth_footer {
  padding-block: 1.375rem;
  &__container {
    @include m.centerWithFlex;
    position: relative;
  }
  &__language {
    position: absolute;
    @include m.centerColumnWithPositioning;
    left: 0;
  }
  &__chat {
    position: absolute;
    @include m.centerColumnWithPositioning;
    right: 0;
  }
}