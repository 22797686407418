.common_confirmation_sub_page {
  position: fixed;
  inset: 0;
  z-index: 15;
  overflow-y: scroll;
  background-color: var(--white-color);
  &__close {
    position: fixed;
    inset: 1.25rem 1.25rem auto auto;
  }
  &__content {
    margin: 4.5rem auto;
    height: 1px;
    min-height: calc(100vh - 9rem);
    width: calc(min(90vw, 37.5rem));
  }
}