@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.pagination-header {
  color: var(--secondary-dark-color);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: v.$default-letter-spacing;
  margin-bottom: 1rem;
  @include m.hideOnBreakpoint("mobile");
}