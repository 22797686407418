@use "../../../../assets/scss/abstracts/mixins" as m;

.funding_request_available {
  display: flex;
  flex-direction: column;
  padding-top: 7.5rem;
  &__approved {
    @include m.box(4rem);
    background-color: rgba(22, 185, 87, 0.16);
    @include m.centerWithFlex;
    border-radius: 50%;
    margin-bottom: 1.5rem;
  }
  &__texts {
    margin-block: 0.75rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__block {
    border-radius: 1rem;
    background-color: var(--white-color);
    border: 1px solid var(--stroke-gray-color);
    box-shadow: 0 0.5rem 3.5rem -0.5rem rgba(26, 29, 61, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    width: 100%;
  }
  &__sum {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }
  &__sub {
    font-weight: 400;
    font-size: 0.875rem;
    color: var(--secondary-dark-color);
  }
  &__submit {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}