@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.common-input {
  padding: 0.625rem 0.75rem;
  border: 1px solid var(--stroke-gray-color);
  min-height: 2.5rem;
  width: 100%;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  color: var(--primary-dark-color);
  line-height: 140%;
  letter-spacing: v.$default-letter-spacing-higher;
  &--error {
    border-color: var(--error-color);
  }
  &--disabled {
    color: var(--additional-dark-color);
  }
  &:active, &:focus {
    outline: none;
    border-color: var(--black-color);
  }
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  &__inner {
    position: relative;
  }
  &__loader {
    position: absolute;
    @include m.box(1rem);
    @include m.centerColumnWithPositioning;
    @include m.centerWithFlex;
    right: 1rem;
  }
}