@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

.tabs-navigation {
  width: fit-content;
  background-color: var(--primary-gray-color);
  border-radius: 0.625rem;
  &__list {
    padding: 0.125rem;
    display: flex;
    align-items: stretch;
    gap: 0.125rem;
  }
  &__element {
    & > * {
      display: block;
      padding: 0.5rem 0.75rem;
      color: var(--secondary-dark-color);
      transition: v.$default-transition;
      border-radius: 0.625rem;
      cursor: pointer;
      &:hover, &.active {
        color: var(--primary-dark-color);
      }
      &.active {
        background-color: var(--white-color);
      }
    }
  }
}