.payments_list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &__element {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.5rem;
    &:not(:last-child) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1rem;
        left: 0;
        background-color: var(--stroke-gray-color);
      }
    }
  }
}