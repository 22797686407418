.common-stat-block {
  min-height: 7.5rem;
  flex: 1;
  background-color: var(--primary-gray-color);
  border-radius: 1rem;
  padding: 1.5rem;
  position: relative;
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.25rem;
  }
  &__text {
    line-height: 140%;
    letter-spacing: -0.02em;
  }
}