@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

.main {
  max-height: calc(100vh - 5.5rem);
  overflow-y: scroll;
  @include m.hideScrollbar;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &--min {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
  }
}