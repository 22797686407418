@use "src/assets/scss/abstracts/mixins" as m;

.market_buttons {
  display: flex;
  @include m.media("mobile") {
    gap: 0.5rem;
    & > * {
      flex: 1;
    }
  }
}