.button {
  position: relative;
  &--disabled {
    background-color: #f0f1f5;
    color: #A8ACBA !important;
    user-select: none;
    pointer-events: none;
  }
  &__loader {
    min-height: 0.75rem;
  }
}