@use "../../../../assets/scss/abstracts/mixins" as m;

.funding-request-file {
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--primary-gray-color);
  display: flex;
  align-items: center;
  gap: 0.25rem;
  &__name {
    font-size: 0.75rem;
    font-weight: 400;
  }
  &__close {
    @include m.box(1rem);
    @include m.centerWithFlex;
    cursor: pointer;
  }
}