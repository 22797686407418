.common_list_entry {
  border-top: 1px solid var(--stroke-gray-color);
  transition: all 0.3s ease-out;
  background-color: var(--white-color);
  &:last-child {
    border-bottom: 1px solid var(--stroke-gray-color);
  }
  &:hover {
    background-color: var(--primary-gray-color);
  }
}