@use "../grid" as gr;
@use "src/assets/scss/abstracts/functions" as f;

$grid-areas: name, target, collected, sum, interest, date, schedule;

.fundraising_investment {
  & > a {
    @include gr.gridValues;
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: $area;
    }
  }
}