@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

$grid-columns: 9.5rem 9.5rem 9.5rem 6rem 9.5rem 9rem;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "id target collected rate date schedule";
  grid-column-gap: 1.5rem;
}