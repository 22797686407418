.my_active_project_aside {
  &__list {
    margin-bottom: 1.5rem;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__term {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;
  }
}