@use "src/assets/scss/abstracts/variables" as v;

.secondary-navigation {
  width: 100%;
  padding-inline: 2.5rem;
  border-bottom: 1px solid var(--stroke-gray-color);
  min-height: 4rem;
  height: 1px;
  display: flex;
  align-items: center;
}