@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.primary-button {
  @include m.centerWithFlex;
  font-size: 0.875rem;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: v.$default-letter-spacing-higher;
  border-radius: 0.625rem;
  padding: 0.75rem 1rem;
  text-align: center;
  border: none;
  transition: v.$default-transition;
  cursor: pointer;
  &--expanded {
    width: 100%;
  }
  &--small {
    padding: 0.5rem 0.75rem;
  }
  &--blue {
    background-color: var(--primary-blue-color);
    color: var(--white-color);
    &:hover {
      background-color: #164ed9;
    }
  }
  &--gray {
    background-color: var(--primary-gray-color);
    color: var(--black-color);
    &:hover {
      background-color: #e1e4eb;
    }
  }
}