@use "../../../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../../../assets/scss/abstracts/variables" as v;

.common-aside-link {
  display: block;
  width: 100%;
  letter-spacing: -0.04em;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 0.75rem;
  color: var(--black-color);
  transition: v.$default-transition;
  background-color: var(--white-color);
  & svg * {
    fill: var(--secondary-gray-color);
  }
  &:hover {
    background-color: var(--primary-gray-color);
  }
  &__inner {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    height: 100%;
  }
  &__icon {
    min-width: 1.125rem;
  }
  &.active {
    background-color: var(--primary-blue-color);
    color: var(--white-color);
    & svg * {
      fill: var(--white-color);
    }
  }
}