@use "../../../../assets/scss/abstracts/mixins" as m;

.common-aside {
  background-color: var(--white-color);
  padding: 1.5rem 1rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100vh - 1rem);
  &__navigation {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__logo {
    max-width: 4rem;
    & img {
      max-width: 100%;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}