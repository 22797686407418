@use "src/assets/scss/abstracts/mixins" as m;
@use "src/components/pages/market/primary-market/grid" as gr;

.primary-market {
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    &--sorting {
      @include m.hideOnBreakpoint("mobile", "min");
    }
  }
  &__list-header {
    padding-inline: 1.5rem;
    @include gr.gridValues;
  }
}