@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.identification-passport-upload {
  height: 100%;
  border: 2px dashed var(--stroke-gray-color);
  padding: 1.5rem 0.75rem 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0.75rem;
  gap: 1.875rem;
  cursor: pointer;
  text-align: center;
  position: relative;
  &.error {
    border-color: var(--error-color);
  }
  &__image {
    flex: 1;
    @include m.centerWithFlex;
    & > img {
      max-width: 7rem;
    }
    &.loaded {
      opacity: 0.5;
      position: relative;
    }
  }
  &__state {
    position: absolute;
    @include m.centerWithPositioning;
    @include m.box(2rem);
    border-radius: 50%;
    background-color: var(--primary-green-color);
    @include m.centerWithFlex;
    &.error {
      background-color: var(--error-color);
    }
  }
}