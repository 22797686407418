@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

.funding-request-options {
  padding-block: 4.5rem;
  &__heading {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__block {
    border: 1px solid var(--stroke-gray-color);
    box-shadow: v.$default-box-shadow;
    border-radius: 1rem;
    padding: 1.5rem;
    background-color: var(--white-color);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
  }
  &__full {
    grid-column: 1 / -1;
  }
  &__condition {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  &__subheader {
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }
  &__text {
    font-weight: 400;
    color: var(--secondary-dark-color);
  }
  &__value {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  &__schedule {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.5rem;
    &-text {
      font-size: 0.75rem;
      font-weight: 400;
      color: var(--secondary-dark-color);
    }
  }
  &__loader {
    min-height: 15vh;
    position: relative;
  }
  &__preliminary {
    padding: 1.5rem;
    border-radius: 0.75rem;
    background-color: var(--primary-gray-color);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &-heading {
      font-size: 1.125rem;
      margin-bottom: 0.25rem;
      @include m.ttNeueFont;
    }
    &-text {
      font-weight: 400;
      font-size: 1rem;
    }
  }
  &__info {
    @include m.centerWithFlex;
    border-radius: 50%;
    background-color: var(--primary-blue-color);
    @include m.box(1.5rem);
    rotate: 180deg;
    color: var(--white-color);
  }
}