$primary-gray-color: #f0f2f8;
$secondary-gray-color: #99a3bd;
$stroke-gray-color: #e8eaed;
$primary-blue-color: #004aff;
$primary-dark-color: #262b39;
$secondary-dark-color: #6b7387;
$additional-dark-color: #aeb0b7;
$primary-green-color: #26b553;
$secondary-green-color: #e8fbee;
$additional-green-color: #16b957;
$additional-orange-color: #dEb113;
$white-color: #fff;
$black-color: #000;
$error-color: #e06464;

:root {
  --white-color: #{$white-color};
  --black-color: #{$black-color};
  --error-color: #{$error-color};
  --primary-gray-color: #{$primary-gray-color};
  --secondary-gray-color: #{$secondary-gray-color};
  --stroke-gray-color: #{$stroke-gray-color};
  --primary-blue-color: #{$primary-blue-color};
  --primary-dark-color: #{$primary-dark-color};
  --secondary-dark-color: #{$secondary-dark-color};
  --additional-dark-color: #{$additional-dark-color};
  --primary-green-color: #{$primary-green-color};
  --secondary-green-color: #{$secondary-green-color};
  --additional-green-color: #{$additional-green-color};
  --additional-orange-color: #{$additional-orange-color};
}

$container-breakpoints: ("desktop": 64em, "tablet": 45em, "mobile": 20em);

$default-box-shadow: 0 0.5rem 3.5rem -0.5rem rgba(26, 29, 61, 0.08);
$default-box-shadow-higher: 0 0.5rem 3.5rem -0.5rem rgba(26, 29, 61, 0.12);

$default-letter-spacing: -0.02em;
$default-letter-spacing-higher: -0.04em;

$default-transition: all 0.4s;