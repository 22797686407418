.common-aside-element {
  width: 100%;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--secondary-dark-color);
  &.finished {
    color: var(--black-color);
  }
  &.available {
    color: var(--black-color);
    cursor: pointer;
  }
  &.active {
    background-color: var(--primary-blue-color);
    color: var(--white-color);
    font-weight: 550;
  }
}