.content {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 0.5rem;
  position: relative;
  background-color: var(--primary-gray-color);
  padding: 0.5rem;
  &--auth {
    background-color: var(--white-color);
    grid-template-rows: auto 1fr auto;
  }
  &--min {
    grid-template-rows: 1fr;
  }
}